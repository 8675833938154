import * as styles from "./alignment-wrapper.module.scss"

import PropTypes from "prop-types"
import React from "react"
import classNames from "../../utils/class-names"

const horizontalAlignments = {
  left: styles.alignLeft,
  middle: styles.alignMiddleHorizontal,
  right: styles.alignRight,
}

const verticalAlignments = {
  top: styles.alignTop,
  middle: styles.alignMiddleVertical,
  bottom: styles.alignBottom,
}

const AlignmentWrapper = ({
  horizontalAlignment,
  verticalAlignment,
  children,
}) => {
  const classes = classNames([
    styles.alignmentWrapper,
    horizontalAlignments[horizontalAlignment],
    verticalAlignments[verticalAlignment],
  ])

  return <div className={classes}>{React.Children.only(children)}</div>
}

AlignmentWrapper.propTypes = {
  horizontalAlignment: PropTypes.oneOf(["left", "middle", "right"]),
  verticalAlignment: PropTypes.oneOf(["top", "middle", "bottom"]),
  children: PropTypes.node.isRequired,
}

AlignmentWrapper.defaultProps = {
  horizontalAlignment: "middle",
  verticalAlignment: "middle",
}

export default AlignmentWrapper
